



































































import KCard from '@/@core/components/other/KCard.vue';
import KText from '@/@core/components/typography/KText.vue';
import { isMobile } from '@/@core/helpers/useBreakpoint';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: { KCard, KText },
  name: 'IndustryFormInfo',
  props: {
    title: {
      type: String,
      required: true
    },
    complete: {
      type: Boolean,
      required: true
    },
    missingData: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean
    }
  },
  setup() {
    const icon = (isComplete: boolean) =>
      isComplete
        ? 'mdi-check-circle-outline'
        : 'mdi-close-circle-outline';
    const iconColor = (isComplete: boolean) =>
      isComplete ? 'primary' : 'warning';
    const textColor = (isComplete: boolean) =>
      isComplete ? 'primary--text' : 'warning--text';
    const textMessage = (isComplete: boolean) =>
      isComplete ? 'Data lengkap' : 'Data belum lengkap';

    const cutSize = 1;

    const restMissingDataCount = (data: any[]) => {
      const cuttedData = data?.slice(cutSize, data?.length);

      if (cuttedData.length < cutSize) return 0;

      return cuttedData.length;
    };

    return {
      iconColor,
      textColor,
      textMessage,
      icon,
      isMobile,
      restMissingDataCount,
      cutSize
    };
  }
});
