var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('k-card',_vm._g(_vm._b({staticClass:"px-4 py-3 mb-4",attrs:{"disabled":_vm.disabled,"min-height":"120px"}},'k-card',attrs,false),Object.assign({}, _vm.$listeners, on)),[_c('v-layout',{staticClass:"align-center"},[_c('div',[_c('k-text',{staticClass:"title",attrs:{"text":_vm.title}}),_c('v-layout',{staticClass:"align-center"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":_vm.iconColor(_vm.complete)},domProps:{"textContent":_vm._s(_vm.icon(_vm.complete))}}),_c('k-text',{staticClass:"text-subtitle-2 font-weight-regular",class:_vm.textColor(_vm.complete),attrs:{"text":_vm.textMessage(_vm.complete)}})],1)],1),_c('v-spacer'),_c('v-icon',{staticClass:"justify-end",domProps:{"textContent":_vm._s('mdi-chevron-right')}})],1),(_vm.missingData && _vm.missingData.length)?_c('v-layout',{staticClass:"mt-1",attrs:{"column":""}},[_vm._l((_vm.missingData.slice(0, _vm.cutSize)),function(data,index){return _c('div',{key:index,staticClass:"text--secondary caption ml-6",domProps:{"textContent":_vm._s(data.text + ' belum diisi')}})}),(_vm.restMissingDataCount(_vm.missingData))?_c('div',{staticClass:"text--secondary caption ml-6",domProps:{"textContent":_vm._s(
            ("...dan " + (_vm.restMissingDataCount(
              _vm.missingData
            )) + " data lainnya")
          )}}):_vm._e()],2):_vm._e()],1)]}}])},[_c('span',[(_vm.complete)?_c('div',{domProps:{"textContent":_vm._s('Data lengkap')}}):_vm._e(),_vm._l((_vm.missingData),function(data,index){return _c('div',{key:index,domProps:{"textContent":_vm._s(index + 1 + '. ' + data.text + ' belum diisi')}})})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }